import styled from 'styled-components/macro';

import { ScQRButton } from 'components/qr/styled';
import { modalBackgroundAnimation } from '../../styles/animation';

export const ScBlock = styled.article`
  width: 460px;
  background: ${({ theme }) => theme.blockBodyBg};
  box-shadow: ${({ theme }) => theme.blockBoxShadow};
  border-radius: ${({ theme }) => theme.blockBorderRadius};
  position: relative;
  animation: ${modalBackgroundAnimation} 1s ease;
  .message {
    margin-bottom: 20px;
  }
  @media (max-width: ${({ theme }) => theme.mediaQuery.medium}) {
    width: 100%;
    margin-top: 10px;
  }
`;

export const ScHeader = styled.header`
  min-height: 131px;
  background: ${({ theme }) => theme.blockHeaderBg};
  border-radius: ${({ theme }) =>
      `${theme.blockBorderRadius} ${theme.blockBorderRadius}`}
    0 0;
  padding: 28px 16px;
  color: ${({ theme }) => theme.blockHeaderTextColor};
  display: flex;
  .email {
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 250px;
    display: inline-block;
    padding-right: 24px;
    svg {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  @media (max-width: ${({ theme }) => theme.mediaQuery.medium}) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-height: 768px) {
    padding: 16px;
    min-height: 100%;
  }
  h3 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 12px;
    &.red {
      color: #e85353;
    }
  }
  ul {
    li {
      margin-bottom: 7px;
      &:last-child {
        margin: 0;
      }
      span {
        display: inline-block;
        line-height: 16px;
        vertical-align: top;
      }
      span:first-child {
        color: ${({ theme }) => theme.blockHeaderSecondaryTextColor};
        margin-right: 5px;
      }
    }
  }
`;

export const ScBody = styled.div`
  padding: 20px 16px;
  @media (max-height: 768px) {
    padding: 10px;
  }
  .trigger {
    color: ${({ theme }) => theme.linkColor};
    cursor: pointer;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
  & > p {
    color: ${({ theme }) => theme.mainPrimaryTextColor};
    line-height: 21px;
    margin-bottom: 28px;
    @media (max-height: 768px) {
      margin-bottom: 10px;
    }
  }
  & > div {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${ScQRButton} {
    margin: 16px 0;
  }
`;

export const ScBodyContent = styled.div`
  position: relative;
`;

export const ScBlockFooter = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.mainLightTextColor};
  padding: 20px 0 0 0;
  margin-top: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-height: 768px) {
    padding: 10px 0 3px 0;
  }
`;
