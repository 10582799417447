import { keyframes } from 'styled-components/macro';

export const modalAnimation = keyframes`
  0%{
    transform: translateY(10%);
    opacity: 0
  }

  100%{
    transform: translateY(0);
    opacity: 1;
  }
`;

export const copyAnimation = keyframes`
  0%{
    transform: translateY(10%) translateX(-50%);
    opacity: 0
  }

  100%{
    transform: translateY(0) translateX(-50%);
    opacity: 1;
  }
`;

export const modalBackgroundAnimation = keyframes`
  0%{
    opacity: 0
  }

  100%{
    opacity: 1;
  }
`;

export const rotationAnimation = keyframes`
  0%{
    transform: rotate(0);
  }

  100%{
    transform: rotate(360deg);
  }
`;

export const notificationShowAnimation = keyframes`
0%{
    opacity: 0;
    transform: translateX(100%);
  }
50%{
  opacity: 0;
  transform: translateX(100%);
}
100%{
    opacity: 1;
    transform: translateX(calc(-30px))
}
`;

export const placeholderAnimation = keyframes`
   0%{
        background-position: 1000px 0
    }
    100%{
        background-position: 0 0
    }
`;
