const theme = {
  mainFontFamilyImport:
    'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap',
  mainFontFamily: 'Roboto, sans-serif',
  mainFontSize: '14px',
  mainBackground: '#E5E5E5',

  mainPrimaryTextColor: '#232323',
  mainSecondaryTextColor: '#7E7E7E',
  mainLightTextColor: '#E3E3E3',

  linkColor: '#3488B8',
  linkHoverColor: '#0770ad',

  blockBorderRadius: '4px',
  blockBoxShadow: '0 4px 20px rgba(0, 0, 0, 0.06)',
  blockHeaderBg: '#00283f',
  blockHeaderTextColor: '#fff',
  blockHeaderSecondaryTextColor: '#bfbfbf',
  blockBodyBg: '#fff',
  blockBodyTextColor: '',

  buttonPrimaryBg: '#3488b8',
  buttonPrimaryTextColor: '#fff',
  buttonBorderRadius: '4px',
  buttonPrimaryBorder: '',

  buttonSecondaryBg: 'none',
  buttonSecondaryTextColor: '#00283f',
  buttonSecondaryBorder: '1px solid #00283f',

  mediaQuery: {
    small: '320px',
    medium: '460px',
    large: '768px',
  },
};

export default theme;
