import React from 'react';
import {
  ScLoaderBlock,
  ScLoaderCircle,
  ScLoaderH3,
  ScLoaderSpan,
  ScLoaderSpanTitle,
  ScLoaderSpanBlockFooter,
} from './styled';
import {
  ScBlock,
  ScHeader,
  ScBody,
  ScBlockFooter,
} from 'components/block/styled';
import { ReactComponent as Loading } from 'imgs/logo-cryptopay.svg';
import { ReactComponent as LogoIcon } from 'imgs/logo.svg';

import { ScCurrencyImg } from 'components/currencyImg/styled';
import { ScFooter } from 'components/footer/styled';
import { ScLangbar } from 'components/langBar/styled';

export const Loader = () => {
  return (
    <>
      <ScBlock>
        <ScHeader>
          <ScCurrencyImg>
            <ScLoaderCircle />
          </ScCurrencyImg>
          <div className="header_info-block">
            <h3>
              <ScLoaderH3 />
            </h3>
            <ul>
              <li>
                <span>
                  <ScLoaderSpanTitle />
                </span>
                <span>
                  <ScLoaderSpan />
                </span>
              </li>
              <li>
                <span>
                  <ScLoaderSpanTitle />
                </span>
                <span>
                  <ScLoaderSpan />
                </span>
              </li>
            </ul>
          </div>
        </ScHeader>
        <ScBody>
          <LoaderBlock />
          <ScBlockFooter>
            <ScLoaderSpanBlockFooter />
            <ScLoaderSpanBlockFooter />
          </ScBlockFooter>
        </ScBody>
      </ScBlock>

      <ScFooter>
        <LogoIcon />
        <ScLangbar>
          <ScLoaderSpan />
        </ScLangbar>
      </ScFooter>
    </>
  );
};

export const LoaderBlock = () => {
  return (
    <ScLoaderBlock>
      <Loading />
    </ScLoaderBlock>
  );
};
