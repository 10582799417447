import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function useTitle(key: string) {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t(key);
  }, [key, t]);
}
