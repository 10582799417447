import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ScLangbar } from './styled';

export const LangBar: FC = () => {
  const { i18n } = useTranslation();

  return (
    <ScLangbar>
      {i18n.language === 'ru' ? (
        <button
          onClick={() => {
            i18n.changeLanguage('en');
            document.cookie = 'i18next=en';
          }}
        >
          English version
        </button>
      ) : (
        <button
          onClick={() => {
            i18n.changeLanguage('ru');
            document.cookie = 'i18next=ru';
          }}
        >
          Русская версия
        </button>
      )}
    </ScLangbar>
  );
};
