import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const ScButton = styled.button`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 12px;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.buttonBorderRadius};
  border: 0;
  width: 100%;
  text-align: center;
  height: 48px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.buttonPrimaryBg};
  color: ${({ theme }) => theme.buttonPrimaryTextColor};
`;

export const ScButtonPrimary = styled(ScButton)`
  background: ${({ theme }) => theme.buttonPrimaryBg};
  color: ${({ theme }) => theme.buttonPrimaryTextColor};
`;

export const ScButtonSecondary = styled(ScButton)`
  background: ${({ theme }) => theme.buttonSecondaryBg};
  color: ${({ theme }) => theme.buttonSecondaryTextColor};
  border: ${({ theme }) => theme.buttonSecondaryBorder};
`;

export const ScLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 12px;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.buttonBorderRadius};
  border: 0;
  width: 100%;
  text-align: center;
  height: 48px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.buttonPrimaryBg};
  color: ${({ theme }) => theme.buttonPrimaryTextColor};
  margin-top: 10px;
  &:hover {
    color: ${({ theme }) => theme.buttonPrimaryTextColor} !important;
  }
`;

export const ScLinkSecondary = styled(ScLink)`
  background: ${({ theme }) => theme.buttonSecondaryBg};
  color: ${({ theme }) => theme.buttonSecondaryTextColor};
  border: ${({ theme }) => theme.buttonSecondaryBorder};
  &:hover {
    color: ${({ theme }) => theme.buttonSecondaryTextColor} !important;
  }
`;
