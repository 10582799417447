import styled from 'styled-components/macro';

export const ScCurrencyImg = styled.div`
  padding: 10px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  border-radius: 50%;
  box-sizing: border-box;
  width: 70px;
  height: 70px;
  svg {
    width: 50px;
    height: 50px;
  }
  @media (max-width: ${({ theme }) => theme.mediaQuery.medium}) {
    margin-right: 0;
    padding: 5px;
    margin-bottom: 10px;
  }
`;
