import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from 'components/loader';
import { NotFound } from 'modules/notFound';
import { useTitle } from 'hooks/useTitle';

interface InvoiceDataContextType {
  crypto_currency: string;
  crypto_amount: string;
  amount: string;
  currency: string;
  partner_invoice_id: string;
  email: string | null;
  lang: string;
  address: string;
  id: string;
  uri: string;
  check_url: string;
  close_after: number;
  status: number;
  attention_block: {
    ru: string;
    en: string;
  };
  success_url: string;
  isOpenEmailModal: boolean;
  refund_token: string | null;
  cross_chain_payment: boolean;
}

interface InvoiceContextActions {
  changeEmail: (email: InvoiceDataContextType['email']) => void;
  toggleModal: () => void;
}

const initialInvoiceData: InvoiceDataContextType = {
  crypto_currency: '',
  crypto_amount: '',
  amount: '',
  currency: '',
  partner_invoice_id: '',
  email: '',
  lang: '',
  address: '',
  id: '',
  uri: '',
  check_url: '',
  close_after: 0,
  status: 0,
  attention_block: {
    ru: '',
    en: '',
  },
  success_url: '',
  isOpenEmailModal: false,
  refund_token: null,
  cross_chain_payment: false,
};

export const InvoiceDataContext = React.createContext<
  InvoiceDataContextType & InvoiceContextActions
>(initialInvoiceData as InvoiceDataContextType & InvoiceContextActions);

export const InvoiceProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  useTitle('mainTitle');

  const { invoiceid } = useParams<{ invoiceid: string }>();

  const [invoiceStatus, setInvoiceStatus] = useState<any>();
  const [invoiceData, setInvoiceData] = useState<any>();
  const [isOpenEmailModal, setIsOpenEmailModal] = useState(false);

  const statusRequest = useCallback(async () => {
    const getStatus = await fetch(`/api/invoice_status/${invoiceid}`);
    const statusData = await getStatus.json();

    if (statusData.error_code !== 404) {
      setInvoiceStatus(statusData.data);

      fetch(`/api/invoice/${invoiceid}`)
        .then((response) => response.json())
        .then((result) => {
          setInvoiceData(result.data);
          setIsOpenEmailModal(result.data.email == null);
        });
    } else {
      setInvoiceStatus(statusData);
    }
  }, [invoiceid]);

  const changeEmail = (email: string) => {
    setInvoiceData((s: any) => ({ ...s, email }));
  };

  const toggleModal = () => {
    setIsOpenEmailModal((s) => !s);
  };

  useEffect(() => {
    statusRequest();

    const timerStatusCheck = setInterval(() => statusRequest(), 6e4);
    return () => {
      clearInterval(timerStatusCheck);
    };
  }, [invoiceid, statusRequest]);

  return (
    <InvoiceDataContext.Provider
      value={
        {
          ...invoiceData,
          ...invoiceStatus,
          changeEmail,
          isOpenEmailModal,
          toggleModal,
        } as InvoiceDataContextType & InvoiceContextActions
      }
    >
      {!invoiceData && !invoiceStatus ? (
        <Loader />
      ) : invoiceStatus.error_code === 404 ? (
        <NotFound />
      ) : (
        children
      )}
    </InvoiceDataContext.Provider>
  );
};
