import React from 'react';
import { createRoot } from 'react-dom/client';

import 'libs/i18n';
import { App } from 'app/App';

import theme from 'styles/theme';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App theme={theme} />);
