import { createGlobalStyle } from 'styled-components';
import { Theme } from './contracts';

export const GlobalStyles = createGlobalStyle<{ theme: Theme }>`

@import url( ${({ theme }) => theme.mainFontFamilyImport});

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
section,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  font-size: ${({ theme }) => theme.mainFontSize};
  font-family: ${({ theme }) => theme.mainFontFamily};
}

img {
  max-width: 100%;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: ${({ theme }) => theme.linkColor};
}

body {
  position: relative;
  min-width: 375px;
  height: 100%;
  @media (max-width: ${({ theme }) => theme.mediaQuery.medium}) {
    min-width: 100%;
  }
  background: ${({ theme }) => theme.mainBackground};
}
main{
    overflow: hidden;
    position:relative;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.mainBackground};
    @media (max-width: ${({ theme }) => theme.mediaQuery.medium}) {
      height: auto;
      padding: 10px;
  }
   @media (max-height: 768px) {
    height: 100%;
   }
   
}
`;
