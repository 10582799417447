import React from 'react';
import { useTranslation } from 'react-i18next';

import { Block } from 'components/block';
import { ReactComponent as NotFoundIcon } from 'imgs/notFoundIcon.svg';

import { ScNotFoundBody } from './styled';

export const NotFound = () => (
  <Block>
    <NotFoundInner />
  </Block>
);

export const NotFoundInner = () => {
  const { t } = useTranslation();

  return (
    <ScNotFoundBody>
      <NotFoundIcon />
      <h3>{t('notFound.title')}</h3>
      <p>
        {t('notFound.text')}
        <a href="mailto:support@mycryptopay.co">{t('notFound.support')}</a>
      </p>
    </ScNotFoundBody>
  );
};
