import styled from 'styled-components/macro';
import { modalAnimation } from '../../styles/animation';

export const ScAddEmailModal = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
`;

export const ScModalContent = styled.div`
  background: #ffffff;
  z-index: 4;
  animation: ${modalAnimation} 0.3s ease;
  p {
    color: #232323;
    font-size: 14px;
    margin-bottom: 17px;
  }
  label {
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: #e85353;
        &:first-child {
          color: #6d6d6d;
        }
      }
    }
  }
  input {
    width: 100%;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px;
    margin: 4px 0 40px 0;
    .error {
      border-color: #e85353;
    }
  }
`;
