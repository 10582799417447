import React, { FC, useContext, useState } from 'react';

import { InvoiceDataContext } from 'providers/invoiceProvider';
import { ScAddEmailModal, ScModalContent } from './styled';
import { ScButton } from 'components/button/styled';
import { useTranslation } from 'react-i18next';

/* eslint-disable */
const regExpEmail =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

type Email = {
  value: string;
  error: boolean;
  changed: boolean;
};

interface EmailModalProps {
  initEmail?: string | null;
}

export const EmailModal: FC<EmailModalProps> = ({ initEmail }) => {
  const { t } = useTranslation();

  const invoiceDataContext = useContext(InvoiceDataContext);

  const [email, setEmail] = useState<Email>({
    value: initEmail || '',
    error: false,
    changed: false,
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (regExpEmail.test(email.value) && email.value.length < 255) {
      fetch(`api/invoice/${invoiceDataContext.id}`, {
        method: 'PUT',
        body: JSON.stringify({ email: email.value }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      })
        .then((response) => response.json())
        .then(() => {
          setEmail({ ...email, changed: true });
          invoiceDataContext.changeEmail(email.value);
          invoiceDataContext.toggleModal();
        });
    } else {
      setEmail({ ...email, error: true });
    }
  };

  return (
    <ScAddEmailModal>
      <ScModalContent>
        <form onSubmit={handleSubmit}>
          <p> {t('email.message')}</p>
          <label>
            <div>
              <span>Email</span>
              {email.error ? <span>{t('email.error')}</span> : null}
            </div>
            <input
              type="text"
              onChange={(e) => setEmail({ ...email, value: e.target.value })}
              value={email.value}
            />
          </label>
          <ScButton>{t('email.next')}</ScButton>
        </form>
      </ScModalContent>
    </ScAddEmailModal>
  );
};
