import styled from 'styled-components/macro';

export const ScLangbar = styled.div`
  margin-top: 12px;
  button {
    border: 0;
    background: none;
    color: ${({ theme }) => theme.mainSecondaryTextColor};
    cursor: pointer;
    font-size: 12px;
    outline: none;
    &:active {
      outline: none;
    }
  }
`;
