import styled from 'styled-components/macro';

export const ScNotFoundBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  min-height: 400px;
  @media (max-width: ${({ theme }) => theme.mediaQuery.medium}) {
    min-height: 200px;
    padding: 10px;
  }
  @media (max-height: 768px) {
    min-height: auto;
  }
  svg {
    margin-bottom: 20px;
  }
  h3 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 5px;
    color: ${({ theme }) => theme.mainColor}};
  }
  p {
    line-height: 21px;
    text-align: center;
  }
`;
