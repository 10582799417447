import React, { FC, lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { deskieWidget } from '3rdParty/deskieWidget';
import { GlobalStyles } from 'styles/globalStyles';
import { NotFound } from 'modules/notFound';
import { Theme } from 'styles/contracts';

const StatusChecker = lazy(() => import('modules/statusChecker'));

export const App: FC<{ theme: Theme }> = ({ theme }) => {
  useEffect(() => deskieWidget.add(), []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />

      <Router>
        <Suspense fallback={null}>
          <Switch>
            <Route path="/:invoiceid" component={StatusChecker} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
};
