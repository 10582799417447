import styled from 'styled-components/macro';

export const ScFooter = styled.div`
  margin: 28px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ScGoBackButton = styled.button`
  border: 0;
  background: none;
  color: #7e7e7e;
  font-size: 14px;
  cursor: pointer;
  margin: 0;
  padding: 0;
  outline: none;
  svg {
    margin-right: 8px;
    path {
      fill: #7e7e7e;
    }
  }
`;
