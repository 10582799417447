import styled from 'styled-components/macro';

import Logo from 'imgs/logo-cryptopay.svg';
import { rotationAnimation, placeholderAnimation } from 'styles/animation';

export const ScLoader = styled.div`
  color: #000;
  width: 50px;
  height: 50px;
  background: url(${Logo}) #fff no-repeat 50% 50%;
  background-origin: content-box;
  padding: 5px;
  box-sizing: content-box;
  border-radius: 50%;
  animation: ${rotationAnimation} 2s linear infinite;
  margin-bottom: 50px;
`;

export const ScLoaderBlock = styled.div`
  min-height: 400px;
  color: #232323;
  text-align: center;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-height: 768px) {
    min-height: 200px;
  }
  @media (max-width: ${({ theme }) => theme.mediaQuery.medium}) {
    min-height: 200px;
  }
`;

export const ScLoadingPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;
  background-color: #fff;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.1) 0,
    rgba(0, 0, 0, 0.3) 15%,
    rgba(0, 0, 0, 0.1) 30%
  );
  background-size: 1000px 100%;
  animation: ${placeholderAnimation} 1.5s linear infinite;
`;

export const ScLoaderCircle = styled(ScLoadingPlaceholder)`
  border-radius: 50%;
`;

export const ScLoaderH3 = styled(ScLoadingPlaceholder)`
  height: 10px;
  width: 250px;
`;
export const ScLoaderSpanTitle = styled(ScLoadingPlaceholder)`
  height: 10px;
  width: 50px;
`;
export const ScLoaderSpan = styled(ScLoadingPlaceholder)`
  height: 11px;
  width: 100px;
`;

export const ScLoaderSpanBlockFooter = styled(ScLoadingPlaceholder)`
  height: 16px;
  width: 150px;
`;
