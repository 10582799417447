import React, { FC } from 'react';

import { ScCurrencyImg } from './styled';
import { ReactComponent as TetherIcon } from 'imgs/tether-icon.svg';
import { ReactComponent as EthereumIcon } from 'imgs/ethereum-icon.svg';
import { ReactComponent as BitcoinIcon } from 'imgs/bitcoin-icon.svg';
import { ReactComponent as CurrencyDefault } from 'imgs/currencyDefault.svg';

export const CurrencyImg: FC<{ currency: string }> = ({ currency }) => {
  function cryptoImg() {
    switch (currency) {
      case 'BTC':
        return <BitcoinIcon />;
      case 'ETH':
        return <EthereumIcon />;
      case 'USDT_ERC20':
        return <TetherIcon />;
      default:
        return <CurrencyDefault />;
    }
  }
  return <ScCurrencyImg>{cryptoImg()}</ScCurrencyImg>;
};
