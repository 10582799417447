import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from 'imgs/arrowIcon.svg';
import { ScBlockFooter } from 'components/block/styled';

import { ScGoBackButton } from './styled';

export const BodyFooter = () => {
  const { t } = useTranslation();

  return (
    <ScBlockFooter>
      {window.history.length > 2 ? (
        <ScGoBackButton onClick={() => window.history.back()}>
          <ArrowIcon />
          {t('footer.back')}
        </ScGoBackButton>
      ) : null}
      <a href="mailto:support@mycryptopay.co"> {t('footer.help')}</a>
    </ScBlockFooter>
  );
};
