import React, { FC, ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { LangBar } from 'components/langBar';
import { CurrencyImg } from 'components/currencyImg';
import { BodyFooter } from 'components/footer';
import { EmailModal } from 'components/email';
import { ScFooter } from 'components/footer/styled';
import { InvoiceDataContext } from 'providers/invoiceProvider';

import { ScBlock, ScBody, ScBodyContent, ScHeader } from './styled';

import { ReactComponent as EditIcon } from 'imgs/edit-icon.svg';
import { ReactComponent as LogoIcon } from 'imgs/logo.svg';

export const Block: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const invoiceDataContext = useContext(InvoiceDataContext);

  return (
    <>
      <ScBlock>
        <ScHeader>
          <CurrencyImg currency={invoiceDataContext.crypto_currency} />
          <div>
            <h3
              className={
                !invoiceDataContext.partner_invoice_id ? 'red' : undefined
              }
            >
              {t('invoice.title')} №{invoiceDataContext.partner_invoice_id}
            </h3>
            <ul>
              <li>
                <span>Email:</span>
                <span className="email">
                  {invoiceDataContext.email ? (
                    <>
                      {invoiceDataContext.email}
                      <EditIcon onClick={invoiceDataContext.toggleModal} />
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              </li>
              <li>
                <span>{t('invoice.amount')}:</span>
                <span>
                  {!!invoiceDataContext.amount ? (
                    `${invoiceDataContext.amount} ${invoiceDataContext.currency}`
                  ) : (
                    <>-</>
                  )}
                </span>
              </li>
            </ul>
          </div>
        </ScHeader>
        <ScBody>
          <ScBodyContent>
            {children}
            {invoiceDataContext.isOpenEmailModal && (
              <EmailModal initEmail={invoiceDataContext.email} />
            )}
          </ScBodyContent>
          <BodyFooter />
        </ScBody>
      </ScBlock>

      <ScFooter>
        <LogoIcon />
        <LangBar />
      </ScFooter>
    </>
  );
};
