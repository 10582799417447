import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationRu from 'locales/ru/common.json';
import translationEn from 'locales/en/common.json';

const resources = {
  en: {
    translation: translationEn,
  },
  ru: {
    translation: translationRu,
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: [
        'querystring',
        'cookie',
        'navigator',
        'localStorage',
        'htmlTag',
        'path',
        'subdomain',
      ],
    },
    supportedLngs: ['en', 'ru'],
    fallbackLng: 'en',
  });
