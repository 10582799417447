import styled from 'styled-components/macro';
import { modalAnimation } from 'styles/animation';
import { ScButton } from 'components/button/styled';

export const ScQRButton = styled(ScButton)`
  background: ${({ theme }) => theme.buttonSecondaryBg};
  color: ${({ theme }) => theme.buttonSecondaryTextColor};
  border: ${({ theme }) => theme.buttonSecondaryBorder};
  svg {
    margin-right: 10px;
  }
`;

export const ScQRModal = styled.div`
  background: rgba(0, 0, 0, 0.4);
  border-radius: ${({ theme }) => theme.blockBorderRadius};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  div {
    padding: 12px;
    background: #fff;
    animation: ${modalAnimation} 0.3s ease;
  }
`;
